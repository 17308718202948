



















import { Component, Vue } from "vue-property-decorator";

type Device = {
  id: number;
  fcm_token_active: boolean;
};

@Component
export default class PushNotificationsComponent extends Vue {
  private currentDevice: null | Device = null;

  get deviceInfo() {
    return this.$store.getters["device/deviceInfo"];
  }

  get fcmTokenActive() {
    if (this.currentDevice) return this.currentDevice.fcm_token_active;

    return false;
  }

  set fcmTokenActive(fcmTokenActive: boolean) {
    if (this.currentDevice) {
      this.$store.dispatch("device/updateFcmTokenActive", {
        id: this.currentDevice.id,
        fcm_token_active: fcmTokenActive
      });
    }
  }

  created() {
    this.fetchCurrentDevice();
  }

  fetchCurrentDevice() {
    this.$store.dispatch("device/fetchCurrent", this.deviceInfo.uuid).then(({ data }) => {
      this.currentDevice = { id: data.id, fcm_token_active: data.fcm_token_active };
    });
  }
}
